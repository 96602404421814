<template>
  <div style="margin: auto; text-align: center; margin-top: 100px;">
    <h1>Flower LANDING</h1>

    <router-link :to="{ name: 'login' }">
      <span>Connexion</span>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {
  },
  created () {
    if (this.$store.getters.isAuthenticated) {
      this.redirectToCampingList()
    } else {
      this.redirectToLogin()
    }
  },
  methods: {
    redirectToLogin () {
      this.$router.push({ name: 'login'})
    },
    redirectToCampingList () {
      this.$router.push({ name: 'campingList' })
    }
  }
}
</script>
